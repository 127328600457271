<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <commissions-list-filters
        :periodType="periodType"
        ref="filters"
        :key="`filter-commission-compiled`"
      />
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col cols="12" md="12">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="outline-secondary"
                @click.prevent="clearSearch"
                class="mr-1"
                :disabled="loading"
              >
                Limpar filtros
              </b-button>
              <b-button
                variant="primary"
                @click.prevent="search(1)"
                :disabled="loading"
              >
                <b-spinner v-if="loading" small />
                <feather-icon v-else icon="SearchIcon" class="mr-50" />
                <span>
                  {{ loading ? "Aguarde..." : "Pesquisar" }}
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- Loading Indicator -->
      <div v-if="loading" class="d-flex flex-column align-items-center justify-content-center">
        <b-spinner label="Loading..." variant="primary" style="width: 3rem; height: 3rem; margin-bottom: 1rem;" />
        <p style="color: #f26237;">Aguarde, carregando dados...</p>
        <p style="color: #f26237;">Esta ação pode levar até 5 minutos.</p> 
      </div>

      <!-- Table -->
      <b-table
        v-else
        hover
        :items="items"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="Não foram encontradas comissões para esta busca"
        :busy="loading"
        :sort-by="sortField"
        @sort-changed="sort"
      >
       <!-- Totals Row -->
       <template #thead-top>
          <b-tr class="text-center bg-light">
            <b-th colspan="8"></b-th>
            <b-th>{{ totalizers.total_expected_amount | toCurrency }}</b-th>
            <b-th>{{ totalizers.total_received_amount | toCurrency }}</b-th>
            <b-th colspan="2"></b-th>
            <b-th v-for="month in dynamicMonths" :key="month">
              {{ totalizers[`month_${month}_total`] | toCurrency }}
            </b-th>
          </b-tr>
        </template>

        <!-- Static Columns -->
        <template v-slot:cell(total_expected_amount)="data">
          <span>{{ data.item.total_expected_amount | toCurrency }}</span>
        </template>
        <template v-slot:cell(total_received_amount)="data">
          <span>{{ data.item.total_received_amount | toCurrency }}</span>
        </template>

        <!-- Dynamic Month Columns -->
        <template v-for="month in dynamicMonths" v-slot:[`cell(month_${month}_amount)`]="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{ data.item[`month_${month}_amount`] | toCurrency }}</span>
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Exibindo {{ pageItemsFrom }} a {{ pageItemsTo }} de {{ totalItems }} itens
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="search"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { BCard, BTable, BRow, BCol, BPagination } from "bootstrap-vue";
import CommissionsListFilters from "../components/CommissionsListFilters";
import { getRangeDates } from "@/helpers/date_picker";
import * as types from "../store/types";
import * as commissionPeriods from "@/constants/commission_periods";
import { floatToMoney } from '@/helpers/converters'

export default {
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BPagination,
    CommissionsListFilters
  },
  data() {
    return {
      loading: false,
      tableColumns: [], // Initially empty, will be dynamically populated
      currentPage: 1,
      itemsPerPage: 10,
      sortField: null,
      dynamicMonths: [],
      items: [],
      totalizers: {}, // Store totalizers data
      periodType: 'compiled'
    };
  },
  props: {
    type: String
  },
  computed: {
    ...mapGetters({
      searchResult: types.HISTORICAL_COMMISSIONS_SEARCH_RESULT,
    }),
    totalItems() {
      return this.searchResult?.total || 0;
    },
    pageItemsFrom() {
      return this.searchResult?.from || 0;
    },
    pageItemsTo() {
      return this.searchResult?.to || 0;
    },
  },
  mounted() {
    this.itemsPerPage = 10;
    this.search(1);
  },
  methods: {
    ...mapActions({
      searchComissions: types.SEARCH_HISTORICAL_COMMISSIONS, // Adjust as necessary
    }),
    search(currentPage) {
      if (!this.$refs.filters) return;
      this.currentPage = Number.isInteger(currentPage) ? currentPage : 1;
      this.loading = true;
      const future = this.type === commissionPeriods.FUTURE;
      const {
        customer,
        partnerCompany,
        product,
        consultant,
        structureOption,
        franchise,
        creditType,
        rangeDate,
        maxMonths,
        role,
        specialist_consultant_id,
        onlyNotConciliated,
        financialSolution,
      } = this.$refs.filters;
      const launchDate = getRangeDates(rangeDate);

      this.searchComissions({
        customer,
        partner_company: partnerCompany,
        product,
        consultant,
        structure_option: structureOption,
        franchise,
        credit_type: creditType,
        max_months: maxMonths,
        launch_date_start: launchDate.start,
        launch_date_end: launchDate.end,
        role,
        specialist_consultant_id,
        onlyNotConciliated,
        financialSolution,
        isFuture: future,
        limit: this.itemsPerPage,
        currentPage: this.currentPage,
      })
        .then((response) => {
          const data = response.data.data;  // Access the actual data array

          // Store totalizers from the response
          this.totalizers = response.data.totalizers || {};

          // Check if data exists and has items
          if (data && data.length > 0) {
            // Static Columns
            this.tableColumns = [
              { key: "customer_name", label: "Cliente", sortable: true },
              { key: "owner_name", label: "PN", sortable: true },
              { key: "commission_owner_level", label: "PN Nível", sortable: true },
              { key: "specialist_consultant_name", label: "Especialista", sortable: true },
              { key: "specialist_consultant_level", label: "Especialista Nível", sortable: true },
              { key: "solution_name", label: "Solução Financeira", sortable: true },
              { key: "product_name", label: "Produto", sortable: true },
              { key: "name_partner", label: "Empresa", sortable: true },
              { key: "total_expected_amount", label: "Comissão Prevista Total", sortable: true },
              { key: "total_received_amount", label: "Comissão Recebida Total", sortable: true },
              { key: "payment_parcel", label: "Parcela", sortable: true },
              { key: "contract_status_name", label: "Status do Contrato", sortable: true },
            ];

            // Extract dynamic month keys and populate dynamicMonths
            this.dynamicMonths = Object.keys(data[0])
              .filter(key => key.startsWith('month_'))
              .map(key => key.replace('month_', '').replace('_amount', ''));

            // Add dynamic month columns
            const monthColumns = this.dynamicMonths.map(key => ({
              key: `month_${key}_amount`,
              label: key.replace('_', '/'), // Format as "YYYY/MM"
              sortable: false,
            }));

            this.tableColumns = [...this.tableColumns, ...monthColumns];

            // Populate the items
            this.items = data;
          } else {
            // Handle the case where data is empty or null
            this.items = [];
            this.dynamicMonths = [];
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    sort(event) {
      if (!event.sortDesc && this.sortField === event.sortBy) {
        this.sortField = null;
        this.search();
      } else {
        this.sortField = event.sortBy;
        this.search();
      }
    },
    clearSearch() {
      this.$refs.filters.customer = undefined;
      this.$refs.filters.partnerCompany = undefined;
      this.$refs.filters.product = undefined;
      this.$refs.filters.consultant = undefined;
      this.$refs.filters.structureOption = structureOptions.OWN;
      this.$refs.filters.franchise = undefined;
      this.$refs.filters.creditType = undefined;
      this.$refs.filters.rangeDate = undefined;
      this.$refs.filters.maxMonths = undefined;
      this.$refs.filters.role = undefined;
      this.$refs.filters.specialist_consultant_id = undefined;
      this.$refs.filters.onlyNotConciliated = undefined;
      this.search();
    }
  },
};
</script>


<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.img_action {
  width: 16px;
  height: 16px;
}
#buttons {
  border: 0px;
}
.dark-font {
  color: #6e6b7b;
}
.text-wrap {
  word-wrap: break-word;
}

/* Sticky Header */
.sticky-table thead th {
  position: sticky;
  top: 0;
  background-color: white; /* Adjust to your table's background color */
  z-index: 10; /* Ensure header is above other content */
}

/* Sticky First Column */
.sticky-table td:first-child,
.sticky-table th:first-child {
  position: sticky;
  left: 0;
  background-color: white; /* Adjust to your table's background color */
  z-index: 5; /* Ensure sticky column is above other content */
}

.sticky-table td:nth-child(2),
.sticky-table th:nth-child(2) {
  position: sticky;
  left: 100px; /* Adjust based on the width of your first column */
  background-color: white; /* Adjust to your table's background color */
  z-index: 4; /* Ensure sticky column is above other content */
}
</style>

